import { React, useContext } from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { BiCollapse, BiExpand, BiRefresh } from 'react-icons/bi';
import { FiGrid } from 'react-icons/fi';
import { BsList } from 'react-icons/bs';

import './ReportBasicTable.scss';

import { AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

const ReportBasicTable = ({
  children,
  headingsArray,
  title,
  collapse,
  collapseAll,
  expandAll,
  button,
  controller,
  handleController,
  off_canvas,
  callBack,
  newLayout
}) => {
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);

  return title === 'Ledger Report' ? (
    <SimpleBar
      className={
        title === 'Ledger Report'
          ? off_canvas
            ? 'ledgerreportOffcanvasTableCover border mt-5'
            : 'ledgerreportTableCover border mt-2'
          : 'reportTableCover border mt-5'
      }
    >
      <div className="text-center mb-2 mt-3 p-3 d-flex align-items-center justify-content-between">
        <div className="mx-auto">
          {user && user.branch && user.branch.company_name && (
            <h4 className="text-dark mb-3">{user.branch.company_name}</h4>
          )}
          <h4 className="mb-2 fs-1 text-uppercase text-primary">{title}</h4>
        </div>
        <div>
          <Button
            variant="success"
            className="p-0"
            title="Click here to refresh ledger report"
            onClick={callBack}
          >
            <BiRefresh size={20} className="text-dark" />
          </Button>
        </div>
      </div>
      <Table responsive striped bordered hover>
        <thead>
          <tr className="border-top">
            {!headingsArray ? (
              <>
                <th className="report-table-heading text-dark border-0">
                  {Translate('ACCOUNT')}
                </th>
                <th className="border-0">
                  <div className="report-table-heading d-flex justify-content-end text-dark">
                    {Translate('TOTAL')}
                  </div>
                </th>
              </>
            ) : (
              headingsArray.map(({ title, ...rest }, index) => (
                <th key={index} {...rest}>
                  {title}
                </th>
              ))
            )}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </Table>
    </SimpleBar>
  ) : (
    <Card className="mt-3 p-3">
      {button ? (
        <div className="ms-auto">
          <div>
            {controller ? (
              <Button
                className="ms-auto fs--1 text-dark"
                variant="transparent"
                onClick={handleController}
                style={{ boxShadow: 'none', outline: 'none' }}
              >
                <FiGrid size={20} className="text-dark me-2" />
                {Translate('Layout 2')}
              </Button>
            ) : (
              <Button
                className="ms-auto fs--1 text-dark"
                variant="transparent"
                onClick={handleController}
                style={{ boxShadow: 'none', outline: 'none' }}
              >
                <BsList size={20} className="text-dark me-2" />
                {Translate('Layout 1')}
              </Button>
            )}
          </div>
          <div>
            {controller ? (
              collapse ? (
                <Button
                  className="ms-auto fs--1 text-dark"
                  variant="transparent"
                  onClick={expandAll}
                  style={{ boxShadow: 'none', outline: 'none' }}
                >
                  <BiExpand size={15} className="text-dark me-2" />
                  {Translate('Expand')}
                </Button>
              ) : (
                <Button
                  className="ms-auto fs--1 text-dark"
                  variant="transparent"
                  onClick={collapseAll}
                  style={{ boxShadow: 'none', outline: 'none' }}
                >
                  <BiCollapse size={20} className="text-dark me-2" />
                  {Translate('Collapse')}
                </Button>
              )
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        ''
      )}
      {newLayout ? (
        <div className="ms-auto">
          <div>
            <Button
              className="ms-auto fs--1 text-dark"
              variant="transparent"
              onClick={handleController}
              style={{ boxShadow: 'none', outline: 'none' }}
            >
              <BiExpand size={15} className="text-dark me-2" />
              {Translate('Expand To Next Level')}
            </Button>
          </div>
          {collapse && (
            <div>
              <Button
                className="ms-auto fs--1 text-dark"
                variant="transparent"
                onClick={collapseAll}
                style={{ boxShadow: 'none', outline: 'none' }}
              >
                <BiCollapse size={15} className="text-dark me-2" />
                {Translate('Collapse all')}
              </Button>
            </div>
          )}
        </div>
      ) : (
        ''
      )}

      <div className="text-center p-3">
        {user && user.branch && user.branch.company_name && (
          <h4 className="text-dark mb-3">{user.branch.company_name}</h4>
        )}
        <div>
          <h4 className="mb-2 fs-1 text-uppercase text-primary">{title}</h4>
        </div>
      </div>
      <SimpleBar
        className={
          title === 'Ledger Report'
            ? 'ledgerreportTableCover border'
            : 'reportTableCover border'
        }
      >
        <Table responsive striped bordered hover>
          <thead>
            <tr className="border-top">
              {!headingsArray ? (
                <>
                  <th className="report-table-heading text-dark border-0">
                    {Translate('ACCOUNT')}
                  </th>
                  <th className="border-0">
                    <div className="report-table-heading d-flex justify-content-end text-dark">
                      {Translate('TOTAL')}
                    </div>
                  </th>
                </>
              ) : (
                headingsArray.map(({ title, ...rest }, index) => (
                  <th key={index} {...rest}>
                    {title}
                  </th>
                ))
              )}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </Table>
      </SimpleBar>
    </Card>
  );
};

ReportBasicTable.propTypes = {
  children: PropTypes.any,
  headingsArray: PropTypes.array,
  title: PropTypes.any,
  collapse: PropTypes.bool,
  button: PropTypes.bool,
  collapseAll: PropTypes.func,
  expandAll: PropTypes.func,
  controller: PropTypes.bool,
  handleController: PropTypes.func,
  off_canvas: PropTypes.bool,
  newLayout: PropTypes.bool,
  callBack: PropTypes.func
};

export default ReportBasicTable;
