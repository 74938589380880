import { React, useContext, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import './style.scss';
import AppContext, { AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ReportsContainerNav from './ReportsContainerNav';
import Export from 'module/Report/Common/Export';
import SimpleBar from 'simplebar-react';
import AppPagination from 'components/common/app-pagination/AppPagination';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import SortReport from 'module/Report/Common/SortReport';
import Filter from 'module/Common/TableFilter/Filter';
import RowsPerPage from './RowsPerPage';

const ReportsContainer = ({
  title,
  children,
  url,
  initialMessage,
  successMessage,
  data,
  paginationUrl,
  type,
  loading,
  showPagination,
  setFormData,
  formData,
  sort,
  sortValue,
  dateFrom,
  dateTo,
  moduleName,
  sowFilterForm,
  hideHeading,
  HideBackBrowser,
  showPerson,
  ledger,
  year,
  newExportMethod,
  showRowsPerPage,
  custom_height,
  fromFixedAsset,
  dropDownData,
  enableDropDown,
  interBranch
}) => {
  const {
    config: { isNavbarVerticalCollapsed, pinSidebar }
  } = useContext(AppContext);
  const { setConfig } = useContext(AppContext);
  const { user } = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const navigate = useNavigate();
  useEffect(() => {
    if (!HideBackBrowser) {
      const handlePopstate = event => {
        if (event.state) {
          navigate('/reports');
        }
      };
      window.addEventListener('popstate', handlePopstate);

      return () => {
        window.removeEventListener('popstate', handlePopstate);
      };
    }
  }, []);
  useEffect(() => {
    if (!pinSidebar) {
      let HTML = document.getElementsByTagName('html')[0];
      if (!isNavbarVerticalCollapsed) {
        setConfig('isNavbarVerticalCollapsed', true);

        HTML.classList.add('navbar-vertical-collapsed');
      } else {
        setConfig('isNavbarVerticalCollapsed', false);
        HTML.classList.remove('navbar-vertical-collapsed-hover');
      }
      return () => {
        !isNavbarVerticalCollapsed &&
          HTML.classList.remove('navbar-vertical-collapsed');
      };
    }
  }, []);

  return (
    <div className="position-relative report-container">
      <Card className="position-sticky mb-2">
        <Card.Header className="d-flex flex-row p-0 shadow-sm align-items-center justify-content-between">
          <div className="d-flex  p-0">
            <div className="hdr-menu-btn border-end  p-2">
              <ReportsContainerNav />
            </div>
            {!hideHeading && (
              <div className="ps-3 ">
                <h6 className="fs-1 mb-0 text-uppercase text-primary pt-3 p-0">
                  {Translate(title)}
                </h6>
                {user && user.branch && user.branch.company_name && (
                  <div className="d-flex ">
                    <div className="mb-1" style={{ fontSize: '10px' }}>
                      {user?.branch?.company_name}
                    </div>
                    {/* {!dateFrom && !dateTo && ledger && (
                      <div
                        className="text-secondary ms-4 "
                        style={{ fontSize: '10px' }}
                      >
                        {Translate('This Month')}
                      </div>
                    )} */}
                    {dateFrom && dateTo && (
                      <div
                        className="text-secondary ms-4 "
                        style={{ fontSize: '10px' }}
                      >
                        {Translate('From')}&nbsp;:&nbsp;{dateFrom}
                        &nbsp;&nbsp;&nbsp;&nbsp;{Translate('To')}
                        &nbsp;:&nbsp;
                        {dateTo}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="hdr-close-btn   d-flex">
            {sort && (
              <div className="d-flex align-items-center me-2 border-start ps-2">
                <SortReport
                  formData={formData}
                  sortOptions={sortValue}
                  setFormData={setFormData}
                />
              </div>
            )}
            {url && (
              <div className="py-2  h-100 d-flex border-start">
                <Export
                  url={url}
                  successMessage={successMessage}
                  formData={ledger && formData}
                  initialMessage={initialMessage}
                  newExportMethod={newExportMethod}
                  interBranch={interBranch}
                />
              </div>
            )}
            <div className="border-start p-0 d-flex align-items-center justify-content-center ">
              <Button
                variant="transparent"
                as={Link}
                to="/reports"
                style={{ boxShadow: 'none', border: 'none' }}
              >
                <FaTimes />
              </Button>
            </div>
          </div>
        </Card.Header>
        {!sowFilterForm && (
          <div className="pt-1 ">
            <Filter
              moduleName={moduleName}
              showPerson={showPerson}
              year={year}
              report={true}
              fromFixedAsset={fromFixedAsset}
              dropDownData={dropDownData}
              enableDropDown={enableDropDown}
            />
          </div>
        )}
        {(data && !loading) || type ? (
          <SimpleBar
            className={
              type
                ? ledger
                  ? 'simplebar-report-for-ledger-report-pagination-style'
                  : 'simplebar-report-for-ledger-pagination-style'
                : paginationUrl
                ? 'simplebar-report-style'
                : 'simplebar-report-no-pagination-style new_style'
            }
            style={{
              height: custom_height && `${custom_height}vh`
            }}
            forceVisible="y"
            autoHide={false}
          >
            <Card.Body className="d-flex flex-column align-items-center p-0">
              {children}
            </Card.Body>
          </SimpleBar>
        ) : (
          <Card
            style={{
              height: type ? '75vh' : custom_height ? custom_height : '75.5vh',
              boxShadow: 'none'
            }}
          >
            <LoadingScreen message="Generating Report!" />
          </Card>
        )}
        {paginationUrl && !showPagination && !loading ? (
          <>
            <Card.Footer className="d-flex align-items-center">
              <AppPagination
                data={data}
                url={paginationUrl}
                search={allQueryParams}
              />
              {showRowsPerPage && <RowsPerPage />}
            </Card.Footer>
          </>
        ) : null}
      </Card>
    </div>
  );
};

ReportsContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  dropDownData: PropTypes.any,
  url: PropTypes.string.isRequired,
  initialMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  data: PropTypes.any,
  paginationUrl: PropTypes.any,
  type: PropTypes.bool,
  loading: PropTypes.bool,
  showPagination: PropTypes.bool,
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  sort: PropTypes.bool,
  sortValue: PropTypes.array,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  moduleName: PropTypes.any,
  sowFilterForm: PropTypes.bool,
  hideHeading: PropTypes.bool,
  HideBackBrowser: PropTypes.bool,
  showPerson: PropTypes.bool,
  ledger: PropTypes.bool,
  year: PropTypes.array,
  newExportMethod: PropTypes.bool,
  showRowsPerPage: PropTypes.bool,
  fromFixedAsset: PropTypes.bool,
  custom_height: PropTypes.string,
  enableDropDown: PropTypes.bool,
  interBranch: PropTypes.bool
};

export default ReportsContainer;
